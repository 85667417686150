import React from 'react'
import { Search } from "./Search";
import { mockCompanyDetails } from "../constants/mock";
import ThemeIcon from './ThemeIcon';

const Header = ({ name }) => {
    return (
        <>
            <div className='xl:px-32'>
                <h1 className="text-xl">
                    {name}
                    <Search />
                </h1>
            </div>
            <ThemeIcon />
        </>
    )
}

export default Header
