import './App.css';
import Dashboard from './components/Dashboard';


function App() {
  return (
    <Dashboard/>
  );
}

export default App;
